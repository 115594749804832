import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { GeneralAction } from './general.actions';
import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';

@Injectable()
export class GeneralEffects {

  @Effect() getHealyMembers$ = this.actions$
    .pipe(ofType(GeneralAction.GET_HEALY_MEMBERS))
    .mergeMap((action: Action) => this.api.getHealyMembers(action.payload.data.start, action.payload.data.count, action.payload.data.search).get()
      .mergeMap(response => Observable.from([
        this.generalAction.getHealyMembersSuccess(response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.getHealyMembersFailed()))
    );

  @Effect() uploadDocument$ = this.actions$
    .pipe(ofType(GeneralAction.UPLOAD_DOCUMENT))
    .mergeMap((action: Action) => this.api.uploadDocument().post(action.payload.data, false)
      .mergeMap(response => Observable.from([
        this.generalAction.uploadDocumentSuccess(action.payload.id, response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.uploadDocumentFailed(action.payload.id, )))
    );

  @Effect() getDocuments$ = this.actions$
    .pipe(ofType(GeneralAction.GET_DOCUMENTS))
    .mergeMap((action: Action) => this.api.getDocuments().post(action.payload.data)
      .mergeMap(response => Observable.from([
        this.generalAction.getDocumentsSuccess(action.payload.id, response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.getDocumentsFailed(action.payload.id, )))
    );

  @Effect() databaseConnection$ = this.actions$
    .pipe(ofType(GeneralAction.DATABASE_CONNECTION))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.databaseConnection().post(action.payload.data)
      .mergeMap(response => Observable.from([
        this.generalAction.databaseConnectionSuccess(action.payload.id, response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.databaseConnectionFailed(action.payload.id, error)))
    );

  @Effect() runConnector$ = this.actions$
    .pipe(ofType(GeneralAction.RUN_CONNECTOR))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.runConnector().post(action.payload.data)
      .mergeMap(response => Observable.from([
        this.generalAction.runConnectorSuccess(action.payload.id, response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.runConnectorFailed(action.payload.id, error)))
    );

  @Effect() authoriseEntry$ = this.actions$
    .pipe(ofType(GeneralAction.AUTHORISE_ENTRY))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.authoriseEntry().postToDomain(action.payload.domain, action.payload.data, true, 'json', 'direct-authentication')
      .mergeMap(response => Observable.from([
        this.generalAction.authoriseEntrySuccess(action.payload.id, response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.authoriseEntryFailed(action.payload.id, error)))
    );

  @Effect() request$ = this.actions$
    .pipe(ofType(GeneralAction.REQUEST))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.request().post(action.payload.data, true, 'json')
      .mergeMap(response => Observable.from([
        this.generalAction.requestSuccess(action.payload, response)
      ]))
      .catch((error: any) => Observable.of(this.generalAction.requestFailed(action.payload, error)))
    );

  constructor(private api: GoAPI,
              private actions$: Actions,
              private generalAction: GeneralAction) {
  }
}
